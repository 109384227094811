<!--
 * @Description: 系统管理 系统角色管理 权限组件 systemRoleManagement
 * @Author: yanxiao
 * @Github: 
 * @Date: 2021-04-23 20:14:03
 * @LastEditors: zhoucheng
-->
<template>
  <div>
    <div class="but-box">
      <el-button type="primary"
                 @click="confirm">确认修改</el-button>
    </div>
    <el-row class="tableContent">
      <dt-table ref="tabRef"
                :tableColumnList="tableColumnList"
                :data="tableList"
                :map="listMap"
                :tableConfig='tableConfig'
                :paginationConfig='paginationConfig'
                @getList="getList"
                :tableFun="tableFun"
                :hidePagination="true">
        <template slot="isFirst">
          <el-table-column type="selection"></el-table-column>
        </template>
      </dt-table>
    </el-row>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import dtTable from '@/components/table.vue'
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable
  },
  props: {
    userCode: {
      default: '',
      type: String,
    },
  },
  data () {
    //这里存放数据
    return {
      tableColumnList: [
        {
          prop: 'roleCode',
          label: '角色编号',
        },
        {
          prop: 'roleName',
          label: '角色名称',
        },
      ],
      tableList: {
        list: [],
      },
      listMap: {
        pageNum: 1,
        pageSize: 10000000,
      },
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableFun: { 'selection-change': this.handleChange },
      // 表格多选列表
      massDeleteList: [],
      // 用户默认的角色列表
      roleList: []
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    userCode (n) {
      if (n) {
        this.getUserRole()
      } else {
        this.setTableCheck([])
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
    if (this.userCode) {
      this.getUserRole()
    }
    this.getList()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () {
  }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    confirm () {
      let roleCodeList = []
      this.massDeleteList.forEach(item => {
        roleCodeList.push(item.roleCode)
      });
      console.log("选择了", roleCodeList);
      this.$emit('confirm', this.userCode, roleCodeList)
    },

    // 获取用户所有角色
    getUserRole () {
      let info = {
        userCode: this.userCode,
      }
      console.log('角色请求信息', info);
      this.$systemRoleManagement.queryUserRole(info).then(res => {
        this.roleList = res.resultEntity
        this.setTableCheck(this.roleList)
      })
    },

    setTableCheck (roleList) {
      this.$nextTick(() => {
        console.log("this.tableList.list", this.tableList.list, "roleList", roleList);
        this.$refs.tabRef.$children[0].clearSelection()
        for (let row of this.tableList.list) {
          for (let roleCode of roleList) {
            if (roleCode.roleCode == row.roleCode) {
              this.$refs.tabRef.$children[0].toggleRowSelection(row, true);
              break
            }
          }
        }
      })
    },

    // 获取角色
    getList () {
      this.$systemRoleManagement
        .queryList({})
        .then((response) => {
          this.tableList.list = response.resultEntity
          this.paginationConfig.total = response.resultEntity.length
          this.setTableCheck(this.roleList)
        })
    },
    //表格多选
    handleChange (val) {
      console.log(val);
      this.massDeleteList = val
    },
  },
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.but-box {
  margin: 20px;
}
.tableContent {
  height: 500px;
  overflow: auto;
}
/*修改滚动条样式*/
.tableContent::-webkit-scrollbar {
  width: 2px;
  height: 10px;
  /**/
}
.tableContent::-webkit-scrollbar-track {
  background: #0a2041;
  border-radius: 2px;
}
.tableContent::-webkit-scrollbar-thumb {
  background: #0065bd;
  border-radius: 10px;
}
</style>