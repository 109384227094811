<!--
 * @Description: 系统管理 系统用户管理 systemUserManagement
 * @Author: zhoucheng
 * @Github: 
 * @Date: 2021-04-06 14:18:44
 * @LastEditors: zhoucheng
-->
<template>
  <div class="mainBody">
    <el-row class="topSelectTwoLine">
      <el-form :inline="true"
               :model="searchForm">
        <el-row class="topFirstLine">
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="用户账号">
              <el-input v-model="searchForm.userCode"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="90px"
                          label="姓名">
              <el-input v-model="searchForm.userName"
                        placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="topButton">
          <el-button type="primary"
                     @click="onSubmit">查 询</el-button>
          <el-button type="info"
                     @click="resetForm"
                     style="margin-left:17px;">重 置</el-button>
        </el-row>
      </el-form>

    </el-row>
    <el-row class="tableRow">
      <el-row class="tableTitle">
        <el-col :span="12">用户列表</el-col>
        <el-col :span="12"
                class="dt-button-box">
          <el-button type="primary"
                     @click="insertUser">新 增</el-button>
          <el-button type="info"
                     :style="{'margin-left':'17px',background:MultiSelect.length>0?'#019FE4':'#092C5A'}"
                     @click="roleConfig">角色分配</el-button>
          <el-button type="info"
                     @click="passwordInitialization"
                     :style="{'margin-left':'17px',background:MultiSelect.length>0?'#00C9D6':'#092C5A'}">密码初始化</el-button>
        </el-col>
      </el-row>

      <el-row class="tableContent">
        <!-- 表格 -->
        <dt-table :tableColumnList="tableColumnList"
                  :data="tableList"
                  :map="listMap"
                  :tableConfig='tableConfig'
                  :paginationConfig='paginationConfig'
                  @getList="getList"
                  :tableFun="tableFun">
          <!--  -->
          <template slot="isFirst">
            <el-table-column type="selection"></el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="角色">
              <template slot-scope="scope">
                <el-button @click="handleClick(scope.$index, scope.row)"
                           type="text"
                           size="medium">修改角色</el-button>
              </template>
            </el-table-column>
          </template>
          <template slot="operating">
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-col :span="8">
                  <el-button @click="handleOrderUpdate(scope.$index, scope.row)"
                             type="text"
                             size="medium">编辑</el-button>
                </el-col>
                <el-col :span="8">
                  <el-button type="text"
                             size="medium"
                             @click="handleOrderDelete(scope.$index, scope.row)">删除</el-button>
                </el-col>
              </template>
            </el-table-column>
          </template>
        </dt-table>
      </el-row>
    </el-row>
    <!-- 弹出框 -->
    <el-dialog :title="dialogTitle"
               append-to-body
               :visible.sync="dialogSwitch"
               :close-on-click-modal="false"
               @close=" popClose">
      <el-form :model="inForm"
               :rules="rules"
               ref="inForm"
               :label-position="labelPosition"
               size="small"
               label-width="110px">
        <!-- 第一行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="用户账号"
                          prop="userCode">
              <el-input v-model="inForm.userCode"
                        :readonly="redact"
                        style="width:63%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-show="dialogTitle =='新增用户'">
            <el-form-item label="密码"
                          prop="passWord">
              <el-input v-model="inForm.passWord"
                        style="width:63%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第二行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="姓名"
                          prop="userName">
              <el-input v-model="inForm.userName"
                        style="width:63%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="性别">
              <el-input v-model="inForm.userSex"
                        style="width:63%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第三行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="年龄">
              <el-input v-model="inForm.userAge"
                        style="width:63%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="身份证号"
                          prop="idCard">
              <el-input v-model="inForm.idCard"
                        style="width:63%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 第四行 -->
        <el-row justify="space-around">
          <el-col :span="12">
            <el-form-item label="邮箱"
                          prop="email">
              <el-input v-model="inForm.email"
                        style="width:63%"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="手机号码"
                          prop="phoneNumber">
              <el-input v-model="inForm.phoneNumber"
                        style="width:63%"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-row type="flex"
                  justify="end">
            <el-col :span="12"
                    class="dt-button-box">
              <el-button type="primary"
                         @click="handleSave('inForm')"
                         style="margin-left:17px;">确 定</el-button>
              <el-button @click="dialogSwitch = false"
                         type="info">取 消</el-button>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog :title="userRoleName"
               :visible.sync="isShowUserRole"
               append-to-body>
      <authority :userCode="userCode"
                 @confirm="userRoleConfirm" />
    </el-dialog>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import $axios from "axios";
import dtTable from '@/components/table.vue'
import authority from "./components/authority";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {
    dtTable,
    authority
  },
  data () {
    //这里存放数据
    return {
      tableConfig: { border: true },
      paginationConfig: { layout: 'prev,sizes,pager,next,jumper', total: 0 },
      tableFun: {
        'selection-change': this.handleChange,
      },
      listMap: {
        pageNum: 1,
        pageSize: 15,
      },
      tableColumnList: [
        {
          prop: 'userCode',
          label: '用户账号',
        },
        {
          prop: 'userName',
          label: '姓名',
        },
        {
          prop: 'email',
          label: '邮箱',
        },
        {
          prop: 'phoneNumber',
          label: '联系方式',
        },
      ],
      tableList: {
        list: [
        ],
      },
      dialogSwitch: false, //弹出层
      dialogTitle: '', //弹出框title
      //查询form
      searchForm: {
        userCode: '',
        userName: '',
      },
      pageSize: 15, // 每页的数据条数
      pageNum: 1,
      table: [], //数据

      inForm: {}, //新增form
      redact: true, //是否可编辑
      roleInfoList: [], // 角色信息列表
      MultiSelect: [], // 多选列表
      labelPosition: 'left', //对齐方向
      basicRules: {
        userCode: [{ required: true, validator: this.checkUserCode, trigger: 'blur' }],
        userName: [{ required: true, validator: this.checkUserCode, trigger: 'blur' }],
        //passWord: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        //手机号校验
        phoneNumber: [{ pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/, message: '请输入正确的手机号', trigger: 'blur' }],
        //身份证号
        idCard: [{ pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: '请输入正确的身份证号', trigger: 'blur' }],
        email: [{ pattern: /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/, message: '请输入正确的邮箱', trigger: 'blur' }]
      },
      rules: {
      },
      userRoleName: '',
      userCode: '',
      isShowUserRole: false,
      // 0.一对多，1.多对多
      userRoletype: null
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.queryTableList()
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 查询
    queryTableList () {
      this.searchForm['pageNum'] = this.pageNum;
      this.searchForm['pageSize'] = this.pageSize;
      this.$systemUserManagement.systemManQuery(this.searchForm).then(response => {
        this.tableList.list = response.resultEntity.list
        this.paginationConfig.total = response.resultEntity.total
      })
    },
    //分页
    getList (change) {
      this.pageSize = change.pageSize
      this.pageNum = change.pageNum
      this.queryTableList(this.pageNum)
    },
    // 查询按钮
    onSubmit () {
      this.pageNum = 1;
      this.listMap.pageNum = 1
      this.queryTableList()
    },
    // 重置
    resetForm () {
      this.pageNum = 1
      this.listMap.pageNum = 1
      this.searchForm = {}
      this.queryTableList()
    },
    // 用户权限修改确定
    userRoleConfirm (userCode, roleCodeList) {
      this.rules = {
        passWord: this.basicRules.passWord
      }
      if (this.userRoletype === 0) {
        if (!userCode) {
          return
        }
        this.setUserOneRole(userCode, roleCodeList)
      } else if (this.userRoletype === 1) {
        this.setUserMoreRole(roleCodeList)
      }
      this.isShowUserRole = false
    },
    // 用户角色一对多 / 删除用户时也掉的这个函数用于删除该用户绑定的角色
    setUserOneRole (userCode, roleCodeList) {
      let delInfo = {
        userCode: userCode
      }
      this.$systemRoleManagement.deleteUserRole(delInfo).then(() => {
        if (roleCodeList && roleCodeList.length !== 0) {
          let addInfo = []
          roleCodeList.forEach(item => {
            addInfo.push({
              roleCode: item,
              userCode: userCode
            })
          })
          this.$systemRoleManagement.insertUserRole(addInfo).then(() => {
            this.$message({ message: '修改成功', type: 'success' });
          })
        } else if (roleCodeList === null) {
          return
        } else {
          this.$message({ message: '修改成功', type: 'success' });
        }
      })
    },
    // 用户角色多对多
    setUserMoreRole (roleCodeList) {
      let delRequestList = []
      this.MultiSelect.forEach(userItem => {
        let delInfo = {
          userCode: userItem.userCode
        }
        delRequestList.push(this.$systemRoleManagement.deleteUserRole(delInfo))
      })
      this.isShowUserRole = false
      $axios.all(delRequestList).then(() => {
        if (roleCodeList.length !== 0) {
          let addInfo = []
          this.MultiSelect.forEach(userItem => {
            roleCodeList.forEach(addItem => {
              addInfo.push({
                roleCode: addItem,
                userCode: userItem.userCode
              })
            })
          })
          this.$systemRoleManagement.insertUserRole(addInfo).then(() => {
            this.$message({ message: '修改成功', type: 'success' });
          })
        }
      })
    },

    //查看角色
    handleClick (index, row) {
      this.userRoleName = '修改角色'
      this.userCode = row.userCode
      this.userRoletype = 0
      this.isShowUserRole = true
    },

    // 角色分配
    roleConfig () {
      if (this.MultiSelect.length === 0) {
        this.$message('至少选择一个角色');
        return
      }
      this.userRoleName = '角色分配'
      this.userCode = ''
      this.userRoletype = 1
      this.isShowUserRole = true
    },
    //新增用户按钮
    insertUser () {
      this.inForm = {}
      this.dialogType = 0
      this.dialogTitle = '新增用户'
      this.redact = false
      this.dialogSwitch = true
      this.rules = this.basicRules
    },

    //密码初始化
    passwordInitialization () {
      if (this.MultiSelect.length === 0) {
        this.$message('至少选择一个角色');
        return
      }
      this.$confirm('确认密码初始化?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        for (let index in this.MultiSelect) {
          this.MultiSelect[index].passWord = 'cqybtc1234'
        }
        let requestList = []
        this.MultiSelect.forEach(item => {
          item.passWord = 'cqybtc1234'
          requestList.push(this.$systemUserManagement.updateList([item]))
        })
        $axios.all(requestList).then(() => {
          this.queryTableList()
          this.$message({ showClose: true, message: '密码初始化成功', type: 'success' });
        })
      })
    },

    //编辑
    handleOrderUpdate (index, row) {
      this.dialogTitle = '信息修改'
      this.inForm = this.$deepClone(row)
      this.redact = true
      this.dialogSwitch = true
      this.rules = this.basicRules
    },

    //删除
    handleOrderDelete (index, row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$systemUserManagement.systemDelete(row).then(() => {
          this.$message({ message: '删除成功', type: 'success' });
          this.queryTableList() //获取角色
        })
        // 调用一对多用户角色维表修改方法（将userCode的用户的全部角色删除，[]新增数组里的角色）
        this.setUserOneRole(row.userCode, null)
      })
    },
    //弹出框确定按钮
    handleSave (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 新增用户
          if (this.dialogTitle == '新增用户') {
            this.inForm = {
              ...this.inForm,
              departId: '',
              imgUrl: '',
              profession: '',
              remarks: '',
              userStatus: '1',
              userType: '',
            }
            let info = this.inForm
            this.$systemUserManagement.systemInsert(info).then(() => {
              this.$message({ showClose: true, message: '新增成功', type: 'success' });
              this.queryTableList()
              this.dialogSwitch = false
            }).catch((error) => {
              this.$message({ message: error.resultMsg });
            })
            // 修改信息
          } else if (this.dialogTitle == '信息修改') {
            let info = this.inForm
            this.$systemUserManagement.systemUpdate(info).then(() => {
              this.$message({ message: '修改成功', type: 'success' });
              this.queryTableList() //获取角色
              this.dialogSwitch = false
            }).catch((error) => {
              this.$message({ message: error.resultMsg });
            })
          }
        } else {
          return false;
        }
      });
    },
    handleChange (val) {
      this.MultiSelect = val
    },
    popClose () {
      this.rules = {}
      this.$refs.inForm.resetFields()
    },
    // 校验用户id是否重复
    checkUserCode (rule, value, callback) {
      if (value) {
        let info = {
          userCode: value,
          pageNum: 1,
          pageSize: 10
        }
        if (this.dialogTitle == '新增用户') {
          this.$systemUserManagement.systemManQuery(info).then((response) => {
            if (response.resultEntity.total > 0) {
              callback(new Error("用户编码已存在"))
            } else {
              callback()
            }
          })
        } else if (this.dialogTitle == '信息修改') {
          this.$systemUserManagement.systemManQuery(info).then((response) => {
            if (response.resultEntity.total > 1) {
              callback(new Error("用户编码已存在"))
            } else {
              callback()
            }
          })
        }
      }
    },
  },
}
</script>
<style lang='scss' scoped>
$topSelectHeight: 135px;
.mainBody {
  height: 100%;
  .topSelectTwoLine {
    width: 100%;
    height: $topSelectHeight;
    @include background('blockBg');
    padding-top: 24.3px;
    padding-left: 21px;
    .topSecondLine {
      margin-top: 19.7px;
    }
    .topButton {
      display: flex;
      padding-left: 91px;
      margin-top: 17px;
    }
  }
  .tableRow {
    width: 100%;
    @include background('blockBg');
    margin-top: 16px;
    padding-left: 20.2px;
    padding-right: 20.2px;
    padding-top: 5px;
    .tableTitle {
      height: 32px;
      margin-top: 15px;
    }
    .tableContent {
      margin-top: 10px;
    }
  }
}

.dt-button-box {
  display: flex;
  justify-content: flex-end;
}
</style>